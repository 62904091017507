import React, { useEffect } from "react";

import { graphql } from "gatsby";

import styled from "@emotion/styled";

import HeizoelBestellungPage from "../../../../pages/heizoel/bestellung/[checkoutStep]";
import { sessionStorage } from "../../../../utils/StorageHandler";
import { getParentReferrerUrl } from "../../../../utils/webViewRedirect";

//Add some bottom padding because of the missing Footer:
const Container = styled.div`
    padding-bottom: 5em;
`;

const HeizoelCheckoutWebview = (props) => {
    //Overwrite the Referrer and Params when an Embedding is used.
    useEffect(() => {
        sessionStorage.setItem("app:parameter", "Embedded WebView");
        sessionStorage.setItem("app:referrer", getParentReferrerUrl());
    }, []);

    return (
        <Container>
            <HeizoelBestellungPage {...props} />
        </Container>
    );
};

export default HeizoelCheckoutWebview;

export const query = graphql`
    query {
        page: otherPagesYaml(slug: { eq: "heizoel" }) {
            ...Page
            disabled
        }
    }
`;
